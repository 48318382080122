import { type AppType } from "next/app";
import { api } from "@/utils/api";
import "@/styles/globals.css";
import NextNProgress from "nextjs-progressbar";
import dynamic from "next/dynamic";

const GoogleAdsScript = dynamic(() => import("@/components/GoogleAdsScript"));

const GoogleAnalyticsScript = dynamic(
  () => import("@/components/GoogleAnalytics"),
);

const UmamiAnalyticsScript = dynamic(
  () => import("@/components/UmamiAnalyticsScript"),
);

const Toaster = dynamic(() =>
  import("react-hot-toast").then((module) => module.Toaster),
);

const MyApp: AppType = ({ Component, pageProps: { ...pageProps } }) => {
  return (
    <>
      <NextNProgress
        color="rgb(2,132,199)"
        startPosition={0.0}
        stopDelayMs={0}
        height={2}
        options={{ showSpinner: false }}
      />
      <Component {...pageProps} />
      <Toaster />
      <GoogleAnalyticsScript />
      <GoogleAdsScript />
      <UmamiAnalyticsScript />
    </>
  );
};

export default api.withTRPC(MyApp);
